"use client";

import { getPathname } from "@/i18n/routing";
import { Header } from "@components/Header/Header";
import { Link as RadixLink } from "@radix-ui/themes/dist/cjs/components/link";
import { useLocale, useTranslations } from "next-intl";
import { usePathname } from "next/navigation";
import React from "react";

type Href = Parameters<typeof getPathname>[0]["href"];

function MarketingHeader({
  hideSessionInfo,
  hideLocalePicker,
  showTitleSubtext,
  breadcrumbs,
}: {
  hideSessionInfo?: boolean;
  hideLocalePicker?: boolean;
  showTitleSubtext?: boolean;
  breadcrumbs?: React.ReactNode;
}) {
  const t = useTranslations("Navigation");
  const locale = useLocale();
  const currentPathname = usePathname();

  const isNavigationItemActive = (englishHref: Href) => {
    const pathname = getPathname({
      locale,
      href: englishHref,
    });
    if (!pathname || !currentPathname) return false;
    if (englishHref === "/") {
      return currentPathname === pathname;
    }
    return currentPathname.startsWith(pathname);
  };

  return (
    <Header
      navigationItems={[
        {
          node: <RadixLink href={"/"}>{t("for_clients")}</RadixLink>,
          isActive: isNavigationItemActive("/"),
        },
        {
          node: (
            <RadixLink href={"/for-therapists"}>
              {t("for_therapists")}
            </RadixLink>
          ),
          isActive: isNavigationItemActive("/for-therapists"),
        },
        {
          node: (
            <RadixLink href={"/for-companies"}>{t("for_companies")}</RadixLink>
          ),
          isActive: isNavigationItemActive("/for-companies"),
        },
        {
          node: <RadixLink href={t("blog_link")}>{t("blog")}</RadixLink>,
        },
      ]}
      hideSessionInfo={hideSessionInfo}
      hideLocalePicker={hideLocalePicker}
      showTitleSubtext={showTitleSubtext}
      breadcrumbs={breadcrumbs}
    />
  );
}

export { MarketingHeader };
