import { profile as api } from "@/api/entities";
import FavoriteButton from "@/components/Favorites/FavoriteButton";
import { useTherapistInquiry } from "@/contexts/TherapistInquiryContext";
import { LinkIcon } from "@/design-system/components/LinkIcon/LinkIcon";
import { FullWidthPrimaryButton } from "@/design-system/components/PrimaryButton";
import { TextV2 } from "@/design-system/components/text/TextV2";
import { useMoment } from "@/hooks/useMoment";
import { Box, Flex, Separator } from "@radix-ui/themes";
import {
  AlarmClockCheck,
  HeartHandshake,
  NotebookPen,
  PenLine,
} from "lucide-react";
import { useFormatter, useTranslations } from "next-intl";
import Image from "next/image";
import React, { useEffect, useState } from "react";
import AvailabilityCarousel from "../Availability/AvailabilityCarousel";
import ProfileResponseTimeMessage from "../ProfileResponseTimeMessage/ProfileResponseTimeMessage";
import styles from "./ProfileBookingInfo.module.scss";
import sunnyCloudImage from "../../../../public/images/sunny_cloud.svg";
import { ProfileCompact } from "@/api/api.directory.search";

type ProfileBookingInfoProps = {
  profile: api.Profile;
  upcomingAvailabilitySlots: string[];
  lastActivityAt?: string;
  showBookingControls: boolean;
  onPriceClick: (
    event: React.MouseEvent<HTMLElement>,
    elementId: string,
  ) => void;
  locale?: string;
};

function ProfileBookingInfo(props: ProfileBookingInfoProps) {
  const moment = useMoment();
  const t = useTranslations("Profile");
  const format = useFormatter();
  const {
    profile,
    upcomingAvailabilitySlots,
    lastActivityAt,
    showBookingControls,
    onPriceClick,
    locale,
  } = props;
  const [lastActivityAtText, setLastActivityAtText] = useState<
    string | undefined
  >(undefined);

  // use an effect to ensure we do not encounter a hydration error due to the relativeTime changing between server and client
  // note that this still may happen in development, due to strict mode
  useEffect(() => {
    const getFormattedRelativeTime = (lastActivityAt: string) => {
      const now = new Date();
      const timeDifferenceInMinutes = Math.floor(
        (now.getTime() - new Date(lastActivityAt).getTime()) / (1000 * 60),
      );
      if (timeDifferenceInMinutes < 60) {
        return t("last_active_less_than_an_hour_ago");
      } else {
        return format.relativeTime(new Date(lastActivityAt), now);
      }
    };

    setLastActivityAtText(
      lastActivityAt ? getFormattedRelativeTime(lastActivityAt) : undefined,
    );
    return () => setLastActivityAtText(undefined);
  }, [format, lastActivityAt, t]);

  const sessionsCount = profile.sessions_count;
  const sessionsCountText =
    profile.showSessions && sessionsCount && sessionsCount > 0
      ? sessionsCount < 10
        ? t("sessions_less_than_10")
        : t("sessions_10_or_more", {
            count: Math.floor(sessionsCount / 10) * 10,
          })
      : undefined;

  const isDateWithinTheLastMonth = (dateStr: string): boolean => {
    const inputDate = moment(dateStr);
    const oneMonthAgo = moment().subtract(1, "months");
    return inputDate.isAfter(oneMonthAgo);
  };
  const capitalizeFirstLetter = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };
  const joinedAtText =
    profile.showJoined && profile.profile_approved_at
      ? isDateWithinTheLastMonth(profile.profile_approved_at)
        ? t("first_month")
        : capitalizeFirstLetter(
            moment
              .duration(
                moment().diff(profile.profile_approved_at, "months"),
                "months",
              )
              .humanize(),
          )
      : undefined;

  const { handleSendMessage } = useTherapistInquiry();

  const getFormattedPriceCurrency = (price: number) => {
    return format.number(price, {
      style: "currency",
      currency: profile.price_currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };
  const getFormattedPrice = (price: number) => {
    return format.number(price, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };
  const getFormattedCurrency = () => {
    const formattedCurrency = format.number(0, {
      style: "currency",
      currency: profile.price_currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    // Extract currency and correct spacing between value and currency
    // (e.g. "CHF 20" -> "CHF ", "20 €" -> " €")
    const currencySymbol = formattedCurrency.replace(/\d|/g, "");
    return currencySymbol;
  };
  const getPriceRangeText = () => {
    const prices = [
      profile.primary_session_offer?.price,
      ...profile.secondary_session_offers.map((offer) => offer.price),
      profile.sliding_scale_session_offer?.price,
    ].filter((price): price is number => price != null);
    if (prices.length === 0) {
      return "";
    }
    if (prices.length === 1) {
      return t("session_price", {
        price: getFormattedPriceCurrency(prices[0]),
      });
    }
    const minPrice = getFormattedPrice(Math.min(...prices));
    const maxPrice = getFormattedPrice(Math.max(...prices));
    return t("session_price_range", {
      minPrice,
      maxPrice,
      currency: getFormattedCurrency(),
    });
  };

  const showCalendarLayout =
    upcomingAvailabilitySlots.length > 0 && profile.calendar_enabled;

  return (
    <Flex
      direction={"column"}
      gap={"7"}
      height={"100%"}
      px={{ initial: "5", sm: "0" }}
    >
      <Flex className={styles.container} direction={"column"} py={"5"}>
        <TextV2
          textStyle={"Headline M"}
          weightStyle={"medium"}
          m={"auto"}
          mb={"5"}
        >
          {t("at_a_glance")}
        </TextV2>

        <Box px={"6"}>
          <Flex direction={"column"} gap={"3"}>
            {/* Sliding scale price */}
            {profile.showPrice && getPriceRangeText() && (
              <LinkIcon
                iconType={"chevron"}
                text={getPriceRangeText()}
                onClick={(e) => onPriceClick(e, "cost")}
              />
            )}

            {/* City, country */}
            <TextV2 textStyle={"Body XL"} weightStyle={"medium"}>
              {profile.city.name}, {profile.country}
            </TextV2>

            {/* Availability */}
            <TextV2 textStyle={"Body XL"} weightStyle={"medium"}>
              <SessionFormatAvailabilityText profile={profile} />
            </TextV2>
          </Flex>
        </Box>

        {!profile.has_limited_profile &&
          ((profile.is_available && lastActivityAt) ||
            joinedAtText ||
            sessionsCountText ||
            profile.free_initial_session_offer ||
            profile.status_message) && (
            <>
              <Separator
                orientation={"horizontal"}
                style={{ backgroundColor: "var(--colorV2-grey-medium-dark)" }}
                size={"4"}
                my={"5"}
              />

              <Box px={"5"}>
                <Flex direction={"column"} gap={"5"}>
                  {/* Last active */}
                  {profile.is_available && lastActivityAt && (
                    <Flex direction={"row"} align={"start"} gap={"3"}>
                      <Box className={styles.iconContainer}>
                        <PenLine width={18} height={18} />
                      </Box>
                      <Flex direction={"column"}>
                        <TextV2 textStyle={"Body M"} weightStyle={"medium"}>
                          {t("last_active")}
                        </TextV2>
                        <TextV2 textStyle={"Body S"}>
                          {t("last_active_details", { lastActivityAtText })}
                        </TextV2>
                      </Flex>
                    </Flex>
                  )}

                  {/* Experience on IC */}
                  {(joinedAtText || sessionsCountText) && (
                    <Flex direction={"row"} align={"start"} gap={"3"}>
                      <Box className={styles.iconContainer}>
                        <HeartHandshake width={18} height={18} />
                      </Box>
                      <Flex direction={"column"}>
                        <TextV2 textStyle={"Body M"} weightStyle={"medium"}>
                          {t("experience_on_IC")}
                        </TextV2>
                        <TextV2 textStyle={"Body S"}>
                          {joinedAtText &&
                            sessionsCountText &&
                            t("experience_on_IC_details", {
                              joinedAtText,
                              sessionsCountText,
                            })}
                          {joinedAtText &&
                            !sessionsCountText &&
                            t("experience_on_IC_details_joined_at", {
                              joinedAtText,
                            })}
                          {!joinedAtText &&
                            sessionsCountText &&
                            t("experience_on_IC_details_sessions", {
                              sessionsCountText,
                            })}
                        </TextV2>
                      </Flex>
                    </Flex>
                  )}

                  {/* Free consultation */}
                  {profile.free_initial_session_offer && (
                    <Flex direction={"row"} align={"start"} gap={"3"}>
                      <Box className={styles.iconContainer}>
                        <AlarmClockCheck width={18} height={18} />
                      </Box>
                      <Flex direction={"column"}>
                        <TextV2 textStyle={"Body M"} weightStyle={"medium"}>
                          {profile.free_initial_session_offer.duration
                            ? t("free_consultation_duration", {
                                duration:
                                  profile.free_initial_session_offer.duration,
                              })
                            : t("free_consultation")}
                        </TextV2>
                        <TextV2 textStyle={"Body S"}>
                          {t("free_consultation_details")}
                        </TextV2>
                      </Flex>
                    </Flex>
                  )}

                  {/* Note from therapist */}
                  {profile.status_message &&
                    profile.status_message.length > 0 && (
                      <Flex direction={"row"} align={"start"} gap={"3"}>
                        <Box className={styles.iconContainer}>
                          <NotebookPen width={18} height={18} />
                        </Box>
                        <Flex direction={"column"}>
                          <TextV2 textStyle={"Body M"} weightStyle={"medium"}>
                            {t("note_from_therapist", {
                              therapistName: profile.firstname,
                            })}
                          </TextV2>
                          <TextV2 textStyle={"Body S"}>
                            {profile.status_message}
                          </TextV2>
                        </Flex>
                      </Flex>
                    )}
                </Flex>
              </Box>
            </>
          )}
      </Flex>

      {showBookingControls && (
        <Box
          className={styles.contactBoxContainer}
          display={{ initial: "none", sm: "block" }}
        >
          <Box>
            <Flex className={styles.container} direction={"column"} py={"5"}>
              {/* Calendar */}
              {!profile.has_limited_profile && showCalendarLayout && (
                <AvailabilityCarousel
                  upcomingAvailabilitySlots={upcomingAvailabilitySlots}
                  locale={locale}
                />
              )}

              {/* Contact message */}
              {!profile.has_limited_profile && !showCalendarLayout && (
                <Flex gap={"2"} px={"6"} pb={"6"} pt={"2"}>
                  <Flex direction={"column"} gap={"2"}>
                    <TextV2 textStyle={"Headline S"} weightStyle={"medium"}>
                      {t("contact.title")}
                    </TextV2>
                    <TextV2 textStyle={"Body XL"}>{t("contact.text")}</TextV2>
                  </Flex>

                  <Box width={"115px"} height={"88px"} flexShrink={"0"}>
                    <Image
                      className={styles.image}
                      src={sunnyCloudImage}
                      alt={t("images_alt.booking_image")}
                      priority={true}
                      quality={100}
                    />
                  </Box>
                </Flex>
              )}

              <Flex direction={"column"} gap={"4"} px={"0"} align={"center"}>
                <Flex direction={"column"} width={"100%"} gap={"0"}>
                  {/* Contact CTA */}
                  <Box px={"5"}>
                    <FullWidthPrimaryButton
                      onClick={() => {
                        handleSendMessage("contact_button");
                      }}
                    >
                      {t("send_message", { name: profile.firstname })}
                    </FullWidthPrimaryButton>
                  </Box>
                </Flex>

                {/* Save CTA */}
                <Box px={"5"} width={"100%"}>
                  <FavoriteButton
                    therapist_user_id={profile.user.id}
                    picture_url={profile.profile_picture_url}
                    jobtitle={profile.jobtitle}
                    full_name={profile.full_name}
                    therapist_first_name={profile.firstname}
                    currentPage="therapist_profile_page"
                    isCTA={true}
                  />
                </Box>

                {!profile.has_limited_profile && showCalendarLayout && (
                  <Box width={"115px"} height={"88px"}>
                    <Image
                      className={styles.image}
                      src={sunnyCloudImage}
                      alt={t("images_alt.booking_image")}
                      priority={true}
                      quality={100}
                    />
                  </Box>
                )}
              </Flex>
            </Flex>
          </Box>
          {/* Response time */}
          {!!profile.response_time && (
            <Flex px={"5"} mt={"2"} justify={"center"}>
              <ProfileResponseTimeMessage
                responseTime={profile.response_time}
              />
            </Flex>
          )}
        </Box>
      )}
    </Flex>
  );
}

function SessionFormatAvailabilityText({
  profile,
}: {
  profile: api.Profile | ProfileCompact | null;
}) {
  const t = useTranslations("Profile.session_formats");
  const formatsTexts = [
    profile?.supports_in_person_therapy && t("in_person"),
    profile?.supports_video_therapy && t("online"),
    profile?.supports_text_therapy && t("text"),
  ].filter(Boolean);
  if (formatsTexts.length === 0) {
    return "";
  } else {
    let formatsText = "";
    if (formatsTexts.length === 1) {
      formatsText = t("formatters.one", { format: formatsTexts[0] });
    } else if (formatsTexts.length === 2) {
      formatsText = t("formatters.two", {
        format1: formatsTexts[0],
        format2: formatsTexts[1],
      });
    } else {
      formatsText = t("formatters.three", {
        format1: formatsTexts[0],
        format2: formatsTexts[1],
        format3: formatsTexts[2],
      });
    }
    return formatsText;
  }
}

export { SessionFormatAvailabilityText };

export default ProfileBookingInfo;
